@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

body {
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f8f8;
  max-width: 100%;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  height: 100svh;
  width: 100%;
}

header {
  padding: 2vmin 5vmin;
  font-size: 3vmin;
  font-weight: 500;
  text-transform: uppercase;
  box-shadow: 0 2px 3px -3px #000;
  background-color: #fff;
  z-index: 1;
}

main {
  flex-grow: 1;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.login-form {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 300px;
  max-width: 90vw;
  margin: auto;
  padding: 2vmin 4vmin;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 0 2px #000;
  background-color: #fff;
}

.login-form h4 {
  font-size: 4vmin;
}

.login-form .error-message-box {
  font-size: 2vmin;
  border: 1px solid #ff0000bf;
  color: #ff0000bf;
  padding: 1.5vmin;
  border-radius: 6px;
  line-height: 100%;
}

.login-form button {
  margin-top: 24px;
  border-radius: 8px;
  text-transform: uppercase;
}

aside {
  min-width: 30vmin;
  display: flex;
  flex-direction: column;
  gap: 1vmin;
  padding: 2vmin 0;
  overflow-y: auto;
  box-shadow: 2px 0 1px -2px #000;
  background-color: #fff;
}

@media screen and (max-width: 767px) {
  aside {
    min-width: 16vmin;
  }
  aside ul li {
    padding: 1vmin 1vmin 2vmin !important;
    height: 5vmin !important;
    border-radius: 4px !important;
    line-height: 100% !important;
  }
}

aside ul {
  border-inline-end: none !important;
}

section {
  padding: 3vmin;
  width: 100%;
  overflow-y: auto;
}

footer {
  padding: 2vmin 3vmin;
  text-align: right;
  font-size: 1.5vmin;
  text-transform: uppercase;
  box-shadow: 0 2px 3px 1px #000;
  background-color: #fff;
  z-index: 1;
}

.tradingview-widget-container iframe {
  width: 100%;
  min-height: auto;
}

.dashboard-wrapper .btn-container {
  display: flex;
  align-items: center;
  gap: 24px;
}

.dashboard-wrapper .btn-container button {
  width: 100%;
  min-width: 200px;
  font-size: 2.5vmin;
  padding: 1.5vmin;
  height: auto;
  text-transform: uppercase;
}

.symbol-select-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;
}

.symbol-select-container span {
  font-size: max(2.4vmin, 12px);
  font-weight: 600 !important;
}

.chart-container {
  z-index: 1;
  position: relative;
  width: 100%;
  margin-top: 16px;
  aspect-ratio: 2.6;
}

.chart-container .spinner-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
}

@media screen and (max-width: 991px) {
  .chart-container {
    aspect-ratio: 1;
  }
}

@media screen and (max-width: 767px) {
  .symbol-select-container .ant-select {
    height: 24px !important;
  }
  .chart-container {
    aspect-ratio: 0.7;
  }
  .dashboard-wrapper .btn-container {
    flex-wrap: wrap;
    gap: 8px;
  }
  .dashboard-wrapper .btn-container button {
    width: auto;
    min-width: 15vmin;
    padding: 1vmin;
  }
}

.orders-wrapper h3 {
  font-size: max(2.5vmin, 15px);
  line-height: 90%;
  margin-top: 0;
  margin-bottom: 24px;
}

.custom-order-form {
  margin: 8px 0 24px;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 24px;
}

.form-container {
  flex: 1;
  padding: 12px 32px 12px 24px;
  background-color: #8c8c8c33;
  border-radius: 6px;
}

.input-container {
  margin-bottom: 16px;
  margin-left: -4px;
  margin-right: -12px;
}

.input-container label {
  font-size: 15px;
  color: #000000aa;
  line-height: 200%;
}

.input-container .ant-input-number-group-wrapper {
  width: 100%;
}

.input-container .ant-input-number-outlined {
  background-color: #f5f5f5 !important;
}

.input-container .ant-input-number-group-addon {
  background-color: #bbb;
}

.price-details-container {
  display: flex;
  align-items: flex-end;
  gap: 6px;
  line-height: 200%;
  background-color: #d3d3d3;
  margin-left: -4px;
  margin-right: -12px;
  margin-bottom: 24px;
  padding: 3px 12px 5px;
  border-radius: 4px;
}

.price-details-container span {
  color: #000000aa;
}

.orders-page-wrapper .ant-table-wrapper .ant-table{
  overflow-x: auto;
    width: 100%;
}
.orders-page-wrapper .ant-table-wrapper .ant-table tr td:last-child{
  text-align: center;
  align-items: center;
  }
  .orders-page-wrapper .ant-table-wrapper .ant-table tr td:last-child span{
    cursor: pointer;
  }